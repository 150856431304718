module.exports = {
	title: "OZOM management portal",
	serverOptions: [
		{
			label: "DEV2", // TODO: "DEV"?
			id: "dev",
			channel: "ozom",
			docsVersion: "develop",
			hostname: "dev.ozom.me",
			accessCookieConfig: {
				name: "glient25idozom",
				path: "/glient25/ozom",
				domain: ".dev.ozom.me",
			},
			refreshCookieConfig: {
				name: "refreshozom",
				path: "/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/ozom",
				domain: ".dev.ozom.me",
			},
			glientWsUrl: "wss://api-mgmt.dev.ozom.me/glient25/ozom",
			gupportWsUrl: "wss://api-mgmt.dev.ozom.me/gupport2/ozom",
			ccWsUrl: "wss://api-mgmt.dev.ozom.me/cc2/ozom",
			redirectUrl: "https://dev.ozom.me/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/ozom/redirect",
			revokeUrl: "https://dev.ozom.me/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/ozom/logout",
			invalidateTokenUrl: "https://dev.ozom.me/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/ozom",
			editAccountUrl: "https://dev.ozom.me/api/profile/0e19a68ec8750be0bbf969a35937663b/redirect",
		},
		{
			label: "ALPHA",
			id: "alpha",
			channel: "ozom",
			docsVersion: "develop",
			hostname: "alpha.roc-connect.com",
			accessCookieConfig: {
				name: "glient25idozom",
				path: "/glient25/ozom",
				domain: ".alpha.roc-connect.com",
			},
			refreshCookieConfig: {
				name: "refreshozom",
				path: "/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/ozom",
				domain: ".alpha.roc-connect.com",
			},
			glientWsUrl: "wss://alpha.roc-connect.com/glient25/ozom",
			gupportWsUrl: "wss://alpha.roc-connect.com/gupport2/ozom",
			ccWsUrl: "wss://alpha.roc-connect.com/cc2/ozom",
			redirectUrl: "https://alpha.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/ozom/redirect",
			revokeUrl: "https://alpha.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/ozom/logout",
			invalidateTokenUrl: "https://alpha.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/ozom",
			editAccountUrl: "https://alpha.roc-connect.com/api/profile/0e19a68ec8750be0bbf969a35937663b/redirect",
		},
		{
			label: "SODIMAC",
			id: "prod",
			channel: "ozom",
			docsVersion: "public",
			hostname: "prod.ozom.me",
			accessCookieConfig: {
				name: "glient25idozom",
				path: "/glient25/ozom",
				domain: ".prod.ozom.me",
			},
			refreshCookieConfig: {
				name: "refreshozom",
				path: "/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/ozom",
				domain: ".prod.ozom.me",
			},
			glientWsUrl: "wss://api-mgmt.prod.ozom.me/glient2/ozom",
			gupportWsUrl: "wss://api-mgmt.prod.ozom.me/gupport2/ozom",
			ccWsUrl: "wss://api-mgmt.prod.ozom.me/cc2/ozom",
			redirectUrl: "https://prod.ozom.me/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/ozom/redirect",
			revokeUrl: "https://prod.ozom.me/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/ozom/logout",
			invalidateTokenUrl: "https://prod.ozom.me/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/ozom",
			// editAccountUrl: "",
		},
	],
};
// {
// 	glientWsUrl: "wss://factoryleader.roc-connect.com/glient25/ozom",
// 	gupportWsUrl: "wss://factoryleader.roc-connect.com/gupport2/ozom",
// 	ccWsUrl: "wss://factoryleader.roc-connect.com/cc2/ozom",
// 	redirectUrl: "https://factoryleader.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/ozom/redirect",
// 	revokeUrl: "https://factoryleader.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/ozom/logout",
// 	invalidateTokenUrl: "https://factoryleader.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/ozom",
// 	editAccountUrl: "https://factoryleader.roc-connect.com/api/profile/0e19a68ec8750be0bbf969a35937663b/redirect",
// }
